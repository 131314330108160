import * as React from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Google, GitHub, X } from '@mui/icons-material';
import { alpha, useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../../Media/Logo.png';
import TwitterAuth from '../Providers/Twitter.js';
import GoogleAuth from '../Providers/Google.js';
import GitAuth from '../Providers/Github.js';
//import EmailPassAuth from '../Providers/EmailPass.js';
//import SignUpPage from './SignUp.js';

//import AppleAuth from '../Providers/Apple.js';
//import AppleIcon from '@mui/icons-material/Apple';

const LoginPage = ({EmailPassAuth}) => {

    const theme = useTheme();
    
    const [showPassword, setShowPassword] = React.useState(false);
    
    const handleShowPwd = () => setShowPassword((show) => !show);
    
    const preventDefault = (e) => e.preventDefault();

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    }
    
    const handleMouseUpPassword = (e) => {
        e.preventDefault();
    }

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [mode, setMode] = React.useState("sign-in"); //Default mode is set to sign-in.

    const handleSubmit = (e) => {
        EmailPassAuth(e, email, password, mode); //Pass user input to the EmailPassAuth component.
    }

    return(
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Container component="main" maxWidth="xs">
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'background.paper', borderRadius: 1, p: 4, border: '1px solid', borderColor: alpha(theme.palette.grey[400], 0.4), boxShadow: theme.shadows[4]}}>
                        <img src={logo} alt="Zidualz Logo" style={{ height: 34}} title='Zidualz'></img>
                        <Typography variant="h5" color="textPrimary" sx={{ my: theme.spacing(1), textAlign: 'center', fontWeight: 600,}}>
                            Sign in to Zidualz
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom textAlign="center">Welcome, please sign in to continue</Typography>
                    
                    <Box sx={{ mt: theme.spacing(1), width: '100%' }}>
                            {/*<Stack spacing={1}>
                                <form>
                                    <Button variant="outlined" type="submit" fullWidth size="large" disableElevation name="provider" color="inherit" startIcon={<AppleIcon />} sx={{textTransform: 'capitalize'}}>
                                        <span>Sign in with Apple</span>
                                    </Button>
                                </form>
                            </Stack>*/}
                            <Stack spacing={1} sx={{ mt: theme.spacing(1) }}>
                                <form>
                                    <Button variant="outlined" type="submit" fullWidth size="large" disableElevation name="provider" color="inherit" startIcon={<Google />} sx={{textTransform: 'capitalize'}} onClick={GoogleAuth}>
                                        <span>Sign in with Google</span>
                                    </Button>
                                </form>
                            </Stack>
                            <Stack spacing={1} sx={{ mt: theme.spacing(1) }}>
                                <form>
                                    <Button variant="outlined" type="submit" fullWidth size="large" disableElevation name="provider" color="inherit" startIcon={<GitHub />} sx={{textTransform: 'capitalize'}} onClick={GitAuth}>
                                        <span>Sign in with GitHub</span>
                                    </Button>
                                </form>
                            </Stack>
                            <Stack spacing={1} sx={{ mt: theme.spacing(1) }}>
                                <form>
                                    <Button variant="outlined" type="submit" fullWidth size="large" disableElevation name="provider" color="inherit" startIcon={<X />} sx={{textTransform: 'capitalize'}} onClick={TwitterAuth}>
                                        <span>Sign in with X</span>
                                    </Button>
                                </form>
                            </Stack>
                            
                            <Divider sx={{ mt: 2, mx: 0, mb: 2 }} > or </Divider>
                                
                            <Box component="form" onSubmit={handleSubmit}>
                                <Stack direction="column" spacing={3} sx={{ mb: 2}}>
                                <TextField required fullWidth size='small' 
                                sx={{ pt: theme.spacing(0), pb: theme.spacing(1), fontSize: theme.typography.pxToRem(11), lineHeight: theme.typography.pxToRem(11), bgcolor: 'background.paper' }}
                                inputProps={{ style: { height: '24px', color: 'grey' } }}
                                label='Email' 
                                defaultValue='your@email.com' 
                                id='email-passkey' 
                                name='email'
                                value={email} //Bind value to the state.
                                onChange={(e) => setEmail(e.target.value)} //Update state on change.
                                />

                                <TextField required fullWidth size='small'
                                sx={{ pt: theme.spacing(0), pb: theme.spacing(1), fontSize: theme.typography.pxToRem(11), lineHeight: theme.typography.pxToRem(11) }}
                                InputProps={{
                                              endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label={ showPassword ? 'hide the password' : 'display the password' } 
                                                                                onClick={handleShowPwd}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                                onMouseUp={handleMouseUpPassword}
                                                                                >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ) 
                                            }}
                                inputProps={{ style: { height: '24px', color: 'grey' }}}
                                name="password" 
                                label="Password" 
                                id="password" 
                                placeholder="*****" 
                                autoComplete="current-password" 
                                defaultValue='*****'
                                type={showPassword ? "text" : "password"} //Toggle password visibility
                                value={password} //Bind the value to state.
                                onChange={(e) => setPassword(e.target.value)}
                                >
                                </TextField>

                                </Stack>
                                
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={12} sx={{ justifyContent: 'space-betewen' }}>
                                <FormControlLabel control= {
                                                            <Checkbox name="remember" value="true" color='primary' sx={{ padding: 0.5, '& .MuiSvgIcon-root': { fontSize: 11} }}>
                                                                <Typography sx={{ fontSize: theme.typography.pxToRem(11) }}>
                                                                    <Link href='/' variant='body2'>Forgot Password?</Link>
                                                                </Typography>
                                                            </Checkbox>
                                                           }
                                                  label='Remember me'
                                                  slotProps={{
                                                    typography: {
                                                        color: 'textSecondary',
                                                        fontSize: theme.typography.pxToRem(14)
                                                    }
                                                  }}
                                                        
                                />
                                <Link onClick={preventDefault} href='#' variant='body2' underline='none'>Forgot password?</Link>
                        </Stack>
                        <Button 
                        type='submit' 
                        fullWidth size='large' 
                        variant='outlined' 
                        disableElevation 
                        color='inherit' 
                        sx={{ mt: 3,mb: 2, textTransform: 'capitalize' }}
                        onClick={() => setMode("sign-in")}
                        >Sign in with Email</Button>
                        <Divider sx={{ mt: 2, mx: 0, mb: 2 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Stack direction='row' spacing={1} justifyContent="space-between" alignItems="center" sx={{ justifyContent: 'space-betewen' }}>
                            <Typography variant="body2" color="textSecondary" gutterBottom textAlign="center">Don't have an account?</Typography>
                            <a href="/signup">
                            <Button onClick={() => setMode('sign-up')} sx={{textTransform: 'none'}}>
                                Sign Up
                                </Button>
                            </a>
                        </Stack>
                    </Box>
                            </Box>
                    </Box>
                    </Box>

                </Container>
            </Box>
        )
};

export default LoginPage;