import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography  from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import logo from '../../Media/Logo.png';


//import useUser from 'Context.js'
//const userInitial = user.displayName ? user.displayName.charAt(0).toUpperCase() : 'U';

const bull = (
    <Box component='span' sx={{display: 'inline-block', mx: '2px', transform: 'scale(0.8)'}}>.</Box>
);

const graphic = (
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia sx={{ height: 155 }} image={logo} title="User Avatar"/>
                </Card>
            )

const card = (
    <React.Fragment>
        <CardContent>
            {graphic}
        </CardContent>
        <Divider sx={{ mt: 1.5}}/>
        <CardContent>
            <Typography sx={{ color: 'rgb(255, 255, 255)'}}>User Profile</Typography>
            <Typography variant='h5' component='div' sx={{ color: 'rgb(237,0,137)'}}>User{bull}Profile{bull}Ideas{bull}Card</Typography>
            <Typography sx={{ color: 'rgb(255,255,255)', mb:1.5}}>Filler</Typography>
            <Typography variant='body2' sx={{ color: 'rgb(255,255,255)'}}>Testing Fill
            <br />
            {'"A Users Information"'}
            </Typography>
        </CardContent>
        <ul>
            <li>
        <CardActions>
            <Button size='small' sx={{ color: 'rgb(255,255,255)'}}>Purchase History</Button>
        </CardActions>
        </li>
        <li style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
        <Box
              sx={{
                width: '10px',
                height: '10px',
                backgroundColor: 'orangered',
                borderRadius: '50%', // Circle shape
                marginRight: '8px',
              }}
            ></Box>
            <Typography sx={{color:'dodgerblue'}}>Testing Item 1</Typography>
        </li>
        </ul>
    </React.Fragment>
);

export default function OutlinedCard() {
    return(
        <Box sx={{minWidth: 275, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgb(237, 0, 137)'}}>
            <Card variant='outlined' sx={{ borderColor: 'rgb(0, 162, 255)', borderWidth: 2, borderStyle: 'solid'}}>{card}</Card>
        </Box>
    )
};