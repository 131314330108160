import * as React from 'react';
import { ShoppingCart } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Menu, MenuItem, IconButton } from '@mui/material';

const Checkout = () => {
    
        const [anchorElUser, setAnchorElUser] = React.useState(null);
    
        const handleOpenUserMenu = (e) => {
            setAnchorElUser(e.currentTarget);
        }
    
        const handleCloseUserMenu = (e) => {
            setAnchorElUser(null);
        }
    
        const open = Boolean(anchorElUser);
    
    return(
    <Box>
        <IconButton onClick={handleOpenUserMenu} sx={{ p:0, marginRight: {xs: '8px', sm: '25px'} }}>
        <MenuIcon sx={{ color: 'white', fontSize: 30, marginLeft: {xs: '4px'} }}/>
        </IconButton>
            <Menu
                sx={{ mt: '45px', p: '8px', minWidth: '250px', maxWidth: '300px' }}
                id='shopmenu'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                onClose={handleCloseUserMenu}
            >
                <MenuItem key='Cart'>
                    Checkout <ShoppingCart sx={{color: 'white'}} />
                </MenuItem>
            </Menu>
    </Box>
    )
}

export default Checkout;