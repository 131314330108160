const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

export default firebaseConfig;

export const firebaseConfigTwoPointO = {
apiKey: process.env.REACT_APP_ZIDUALZ_API_KEY,
authDomain: process.env.REACT_APP_ZIDUALZ_AUTH_DOMAIN,
projectId: process.env.REACT_APP_ZIDUALZ_PROJECT_ID,
storageBucket: process.env.REACT_APP_ZIDUALZ_STORAGE_BUCKET,
messagingSenderId: process.env.REACT_APP_ZIDUALZ_MESSAGING_SENDER_ID,
appId: process.env.REACT_APP_ZIDUALZ_APP_ID,
measurementId: process.env.REACT_APP_ZIDUALZ_MEASUREMENT_ID
}