import React from "react";
import { Button } from "@mui/material";
import { ShoppingCart } from "@mui/icons-material";

const AddToCartButton = ({ item, addToCart, size = "medium" }) => {
  return (
    <Button
      variant="contained"
      sx={{
        marginTop: 2,
        marginBottom: 2,
        bgcolor: "rgb(59, 0, 186)",
        color: "white",
        fontFamily: "ka1, sans-serif",
        height: 35,
        "&:hover": {
          bgcolor: "#ff4500",
          boxShadow: "0px 0px 15px rgb(59, 0, 186)",
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 1
      }}
      startIcon={<ShoppingCart sx={{ padding: 0}}/>}
      onClick={() => addToCart(item)}
    />
  );
};

export default AddToCartButton;

