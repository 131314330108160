import * as React from 'react';
import { IconButton, Menu, Box, MenuItem, Button, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import UserMenu from './User/UserMenu';
import LoginWrapper from './LoginSignUp/LoginWrapper';
import { useUser } from '../Context';
import '../Styles/mobilemenu.css'

const MobileMenu = ({logoSize}) => {

    const {user, globalModal } = useUser();
    
    const [anchorEl, setAnchorEl] = React.useState(false);
    
    const open = Boolean(anchorEl);
    
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }
    
    const handleClose = () => {
        setAnchorEl(false);
    }

    //Close the mobile menu when the login modal is displayed
    React.useEffect(() => {
        if (globalModal) {
            handleClose();
        }
    }, [globalModal]);

    //Close the menu when the user logs out.
    React.useEffect(() => {
        if (!user) {
            setAnchorEl(false);
        }
    }, [user]);


    return (
        <Box>    
            <IconButton 
                size="large" 
                sx={{ color: 'rgb(237, 0, 137)' }} 
                onClick={handleClick} 
                aria-controls={open ? 'basic-menu' : undefined} 
                aria-haspopup="true" 
                aria-expanded={ open ? 'true': undefined}>
                    <MenuIcon sx={{ 
                                    fontSize: logoSize ? `calc(${logoSize * 0.7}px)` : "calc(1.2 * 40px)" //Scales proportionally with logo size.
                                  }}
                    />
            </IconButton>
            
            <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button'
            }}
            >
                <MenuItem>
                {!user ? <LoginWrapper /> : <UserMenu user={user} isMobileMenu={true}/>}
                </MenuItem>
                
            </Menu>
        </Box>
        )
}

export default MobileMenu;