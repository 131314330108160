import React from "react";
import { Box, Typography, Button, IconButton, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_51Qc9JZC2DCCNbnzDrO0kfl98Z7i8aQwlJXHteKYpTv6eU6nDNLqLwrnCl2kVekItgJx3UCkReow1lUtyQ4yqruvC00kBnUOndD");

const ShoppingCart = ({ cart, removeFromCart, updateCartItem }) => {
  
  const parsePrice = (price) => {
    const parsed = parseFloat(price.replace("$", ""));
    if (isNaN(parsed)) throw new Error("Invalid price format");
    return parsed;
  };
  

  const InCart = cart.map((item) => ({
    id: item.id,
    name: item.title,
    amount: item.price,
    quantity: item.quantity
  }));

  const getTotal = () => cart.reduce((acc, item) => acc + parsePrice(item.price) * item.quantity, 0);

  const total = (getTotal() / 100).toFixed(2);
  const handleCheckout = async () => {

    const stripe = await stripePromise;

    // Send cart data to your backend
    const response = await fetch("https://createcheckoutsession-uud3fjgkxq-uc.a.run.app", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: cart.map((item) => ({
          id: item.id,
          name: item.title,
          amount: parsePrice(item.price),
          quantity: item.quantity,
        })),
      }),
    });

    const { sessionId } = await response.json();

    // Redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({ sessionId });
    if (result.error) {
      console.error("Stripe Checkout Error:", result.error.message);
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: "white",
        borderRadius: 2,
        maxWidth: 500,
        mx: "auto",
        boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Shopping Cart
      </Typography>

      {cart.length === 0 ? (
        <Typography>Your cart is empty</Typography>
      ) : (
        cart.map((item) => (
          <Box
            key={item.id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
              borderBottom: "1px solid #ddd",
              pb: 1,
            }}
          >
            <Box>
              <Typography>{item.title}</Typography>
              <Typography sx={{ fontSize: 14, color: "gray" }}>
                ${(parsePrice(item.price) / 100).toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                type="number"
                size="small"
                value={item.quantity}
                onChange={(e) =>
                  updateCartItem(item.id, parseInt(e.target.value || 1))
                }
                sx={{ width: 50, mr: 1 }}
              />
              <IconButton
                color="error"
                onClick={() => removeFromCart(item.id)}
              >
                <Delete />
              </IconButton>
            </Box>
          </Box>
        ))
      )}

      <Typography variant="h6" sx={{ mt: 2 }}>
        Total: ${(getTotal() / 100).toFixed(2)}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleCheckout}
        disabled={cart.length === 0}
      >
        Checkout
        {console.log(total)}
      </Button>
    </Box>
  );
};

export default ShoppingCart;
