import React, { useState } from "react";
import { Fab, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import ShoppingCart from "./ShoppingCart";
import { Close } from "@mui/icons-material";
import {ShoppingCart as Carton} from '@mui/icons-material';

const ShoppingCartButton = ({ cart, removeFromCart, updateCartItem }) => {
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //const getTotal = () => cart.reduce((acc, item) => acc + item.price * item.quantity, 0);

    return (
        <>
        {/* Persistent Shopping Cart Button */}
        <Fab
            color="primary"
            sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            bgcolor: "rgb(59, 0, 186)",
            "&:hover": {
                bgcolor: "#ff4500",
            },
            }}
            onClick={handleOpen}
        >
            <Carton />
        </Fab>

        {/* Shopping Cart Modal */}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
            Shopping Cart
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <ShoppingCart cart={cart} removeFromCart={removeFromCart} updateCartItem={updateCartItem}/>
            </DialogContent>
        </Dialog>
        </>
    );
    };

    export default ShoppingCartButton;