import { getFirestore, addDoc, collection, doc, getDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import firebaseConfig from './firebase.config';

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

const dbCollection = collection(db, 'contact'); 

async function writeData(submit_name, submit_email, submit_message) {
    
    const newDoc = await addDoc(dbCollection, {
        contact_name: submit_name,
        contact_email: submit_email,
        contact_message: submit_message 
    });
    
    const docRef = doc(dbCollection, newDoc.id);
    const docSnap = await getDoc(docRef);

    try {
        if (docSnap.exists()) {
        //console.log("Document exists. Doc ID:", newDoc.id);
        return {success: true, id: newDoc.id};
    } else {
        // pass
        }
    } catch(error) {
        //console.log("No such doc.")
        return {success: false, error: error.message}
    }

};

export default writeData;