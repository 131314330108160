import * as React from 'react';
import Image from 'react-bootstrap/Image';
import logo from '../Media/Logo.png';
//import { Row, Container, Col} from 'react-bootstrap';
import Bios from './Bios'
import '../Styles/about.css';

const About = () => {
    return (
      <>
      {/*<React.Fragment>
        <Container>
          <Row>
            <Col className="clipper">
            <Image src='https://storage.googleapis.com/z_static/Trim.png'/>
            </Col>
            </Row>
            </Container>
      </React.Fragment>*/}

      <React.Fragment>
      <section id="about">
        <div className="wrapper">
          <a href='/'>
        <Image src={logo} width={"20%"} height={"40%"}/>
        </a>
          <article>
            <div className="title">
              <h3 className="textStyle">Who are these guys?</h3>
              <p className="separator" />
            </div>
            <div className="desc full">
            <h4 className="subtitle">
                Two innovators applying intelligent systems to the Real Estate space since 2018. 
            </h4>
            
            <div className="title">
              <Bios name={'Zachary Hanson'}></Bios>
              </div>

              <div className="title">
                <p className="blank-separator" />
              </div>
              
              <div className="title">
              <Bios name={'Joe Crimaldi'}></Bios>
              </div>

            </div>
            <div className="title">
              <h3 className="textStyle">What do we do?</h3>
              <p className="separator" />
            </div>
            <div className="desc">
              <h4 className="subtitle">Automate complex systems and processes using machine learning. Map our professional minds to the digital space.</h4>
            </div>
            <div className="desc">
              <h4 className="subtitle">Real estate developers, consultants, and technologists.</h4>
            </div>
          </article>
        </div>
      </section>
      </React.Fragment>
      
    {/*  <React.Fragment>
        <Container>
          <Row>
            <Col>A</Col>
            </Row>
            </Container>
      </React.Fragment>*/}
      
      </>
    );
  };
  
  export default About;