import NavbarBrand from 'react-bootstrap/NavbarBrand';
import Image from 'react-bootstrap/Image';
import logo from '../Media/Logo.png';
import '../Styles/footer.css';

export default function Footer() {
    return(
        <NavbarBrand>
            <a href='/'>
            <Image className="footer" src={logo} width={"20%"} height={"40%"}/>
            </a>
        </NavbarBrand>
    )
};