import {Image} from 'react-bootstrap';
import { Stack, Box, Typography, Link } from "@mui/material";
import Checkout from './ShoppingCartMenu';
import Flamingo from '../../Media/FlamingoZ.png';
import '../../Styles/shop.css';

const ShopBanner = () => {
    return(
        <Box className='retro-console-background'>
            
            <Stack 
                direction="row" 
                alignItems='center' 
                spacing={2} 
                sx={{ 
                    zIndex: 1, //Ensures content appears above the border.
                    justifyContent: 'space-between',
                    width: '100%'
                    }}>

            <Link href='/' underline='none' color='white'>
                <Image src={Flamingo} width={75} height={100} alt='Flamingo Logo' style={{
                    filter: 'drop-shadow(0px 0px 10px #ff0099)',
                }}/>
            </Link>

            <Typography variant='h6' className='text-glow' sx={{
                fontFamily: 'ka1, sans-serif', //Pixelated Retro Font
                fontSize: {xs: 25, md: 50}, 
                color: 'white', 
                textShadow: '0px 0px 20px #ff0099',
                marginRight: {xs: 0},
                textAlign: 'center'
            }}>
                Zidualz {window.innerWidth < 768 && <br />} Official Shop
            </Typography>
            <Checkout />
        </Stack>

    </Box>
        )
}

export default ShopBanner;