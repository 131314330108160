import LandingText from './LandingText.js';
import NextDivider from './NextDivider.js';
import StickyBar from './StickyBar.js';
import mobile from '../Media/Mobile.png';
import MobileLand from '../Media/MobileLand.png';
//import MobileDrawerMenu from './MobileDrawerMenu.js';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import dark from '../Media/DarkMascot.jpg';
import { UserProvider } from '../Context.js';
import MobileBar from './MobileBar.js';

/*The following component, Wrapper, enables a responsive background.*/
const Wrapper = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${mobile})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center', //Centers the background image.
      position: 'fixed',
      minWidth: '100vw',
      minHeight: '100vh',
      '@media (orientation: landscape)': {
        backgroundImage: `url(${MobileLand})`,
        backgroundSize: 'contain', //Ensures full image fits without cropping
        backgroundPosition: 'top center', //Better alignment in landscape.
        minHeight: '100vw', //Switch height/width since landscape width > height
        minWidth: "100vh" //Allow width to resize naturally
      }
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${dark})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      position: 'fixed',
      maxWidth: '100vw',
      minHeight: '100vh',
      minWidth: 'auto'
  
    }
  }));

function LandingPage() {
    return (
      <UserProvider>
      <Wrapper>
        <MobileBar />
        <StickyBar />
        <LandingText />
        <NextDivider />
      </Wrapper>
      </UserProvider>
    );
  }
  
  export default LandingPage;