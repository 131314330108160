import React, { createContext, useContext, useState, useEffect } from 'react';
import { firebaseAppAuth } from './Utils/Authentication/Authenticate';
import { onAuthStateChanged } from 'firebase/auth';

const UserContext = createContext();

export const UserProvider = ({children}) => {
    
    const [globalModal, setGlobalModal] = useState(false); // Default value to false.
    
    const [user, setUser] = useState(false); //Initial state indicates no user yet.

    const auth = firebaseAppAuth;
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user); //Update user state when authentication state changes
            if (user) {
                setGlobalModal(false);
            }
        })

        return unsubscribe; //Cleanup the listener on unmount
    }, [auth]);

    useEffect(() => {
        //The last to fire of console.logs
        //Firing sequence of console.logs that track globalModal are: 1) unsubscribe (defined immediately above) 2)LoginWrapper (Modal state from context) and 3) LoginWrapper (Modal state from login wrapper)
        //console.log('globalModal state updated:', globalModal)
    }, [globalModal])

    return <UserContext.Provider value={{user, globalModal, setGlobalModal}}>{children}</UserContext.Provider>

};

export const useUser = () => useContext(UserContext);