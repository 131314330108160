import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LoginPage from './LoginPage.js';
import { useUser } from '../../Context.js';
import UserMenu from '../User/UserMenu.js';

const LoginWrapper = () => {

    const {user, globalModal, setGlobalModal} = useUser();

    const openModal = () => {
        setGlobalModal(true);
    }

    return (
        <>
        <style type="text/css">
        {`
        .btn-primary {
            position: relative;
            top: 30%;
            font-family: ka1;
            background-color: rgba(0,0,0,0);
            margin-right: 5px;
            border: none;
        }

        .modal-body {
            padding: 0;
            margin: 0;
            border: none;
        }

        .modal-dialog modal-dialog-center {
            padding: 0;
            border: none;
            background-clip: content-box;
        }

        .modal-content {
            padding: 0;
            border: none;
            background-color: rgba(0,0,0,0)
        }
        
        `}
        </style>
        {/* Button to open modal */}
        {!user && <Button onClick={openModal}>Login</Button>}
        {/* Display user menu if logged in */}
        { user && <UserMenu user={user}/>}
        {/* Modal logic */}
        <Modal centered='true' show={globalModal} onHide={() => setGlobalModal(false) }>
            <Modal.Body>
            <LoginPage />
            </Modal.Body>
        </Modal>
    </>
    )
}


export default LoginWrapper;