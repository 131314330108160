import { Avatar, Box, IconButton, Menu, MenuItem, Typography, Divider, Button } from "@mui/material";
import * as React from 'react';
import {signOut} from 'firebase/auth';
import { firebaseAppAuth } from "../../Utils/Authentication/Authenticate";
import { useUser } from "../../Context";
import {Link} from "@mui/material";
import '../../Styles/usermenu.css';
import { createTheme } from "@mui/material";

const theme = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgb(251, 0, 253)" //Background color of the Avatar component.
                }
            }
        }
    }
})

const UserMenu = ({user, isMobileMenu = false}) => {
    
    const {setGlobalModal} = useUser();

    const auth = firebaseAppAuth;
    
    const logoutHandler = () => {
            signOut(auth).then(() => {
                handleCloseUserMenu();
                setGlobalModal(false);
            })
        };

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (e) => {
        setAnchorElUser(e.currentTarget);
    }

    const handleCloseUserMenu = (e) => {
        setAnchorElUser(null);
    }

    const open = Boolean(anchorElUser);

    //Get the first lettter of the user's name (or the name if it's unavailable)

    const userInitial = user.displayName ? user.displayName.charAt(0).toUpperCase() : 'U';

    // Render an expanded list if 'isMobileMenu' is true.
    if (isMobileMenu) {
        return (
            <Box>
                <Typography sx={{ mb: 1, fontFamily: 'ka1', fontSize: '.85rem'}}>
                Welcome, {user.displayName || "User"} !
                </Typography>
                
                <MenuItem key='Profile'>
                <Link href="/profile" underline='none' color='white'>
                <Typography sx={{ textAlign: 'center', fontFamily: 'editundo', fontSize: '1.25rem' }}>Dashboard</Typography>
                </Link>
                </MenuItem>
                
                <MenuItem>
                <Link href="/about" underline='none' color='white'>
                    <Button variant="primary" sx={{ textAlign: 'center', fontFamily: 'editundo', fontSize: '1.1rem' }} alignItems="center">About</Button>
                </Link>
                </MenuItem>
                
                <MenuItem>
                <Link href='/contact' underline='none' color='white'>
                    <Button variant="primary" sx={{ textAlign: 'center', fontFamily: 'editundo', fontSize: '1.1rem' }} alignItems="center">Contact</Button>
                </Link>
                </MenuItem>

                <MenuItem>
                <Link href='/projects' underline='none' color='white'>
                    <Button variant="primary" sx={{ textAlign: 'center', fontFamily: 'editundo', fontSize: '1.1rem' }} alignItems="center">Projects</Button>
                </Link>
                </MenuItem>

                <MenuItem>
                        <Link href='/shop' underline='none' color='white'>
                            <Button variant='primary' sx={{textAlign: 'center', fontFamily: 'editundo', fontSize: '1.1rem'}} alignItems='center'>Shop</Button>
                        </Link>
                </MenuItem>
                
                <Divider  sx={{ mt: 2, mx: 0, mb: 2, borderColor: 'whitesmoke', borderWidth: '0.03rem' }}/>
                
                <MenuItem onClick={logoutHandler}>
                    <Typography sx={{textAlign: 'center', fontWeight: 'bold', fontFamily: 'editundo', fontSize: '1.1rem'}}>Logout</Typography>
                </MenuItem>
            </Box>
        )
    }
    //Default dropdown menu behavior.
    return(
            <Box>
                <IconButton onClick={handleOpenUserMenu} sx={{ p:0 }}>
                    {/* Display Avatar or Initials */}
                <Avatar
                    theme={theme}
                    alt={user.displayName || 'User'} 
                    src={userInitial ? '' : user.photoURL}
                    sx = {{
                        fontFamily: " 'editundo', 'ka1' ",
                        fontSize: '1.5rem', //Adjust font size
                        fontWeight: 'bold', //Customize font weight
                        color: 'white', //Text color of the initials.
                        width: 40, //Adjust the size of the avatar
                        height: 40, //Adjust height.
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'uppercase', //Uppercase initials.
                        //Targeting the text inside the Avatar component.
                        '& > span': {
                            fontFamily: " 'editundo', 'ka1' " //Apply custom font.
                        }
                    }}
                    >
                        {/*Display the first letter if no pohoto URL*/}
                        {/*!user.photoURL && userInitial*/}
                        {userInitial}
                </Avatar>
                </IconButton>
                <Menu 
                sx={{ mt: '45px', p: '8px', minWidth: '250px', maxWidth: '300px' }}
                id='menu-appbar'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                onClose={handleCloseUserMenu}
                >
                    <Typography sx={{ 
                        mb: 1, 
                        fontFamily: 'ka1', 
                        fontSize: '.85rem',
                        textAlign: 'center', // Center-align the message
                        whiteSpace: 'nowrap', // Prevent wrapping text to the next line
                        overflow: 'hidden', // Handle text overflow
                        textOverflow: 'ellipsis' //Add ellipsis if the text exceeds max width
                    }}>
                        Welcome, {user.displayName || "User"} !
                    </Typography>
                    
                    <MenuItem key='Profile'>
                    <Link href="/profile" underline='none' color='white'>
                        <Typography sx={{ textAlign: 'center', fontFamily: 'editundo', fontSize: '1.25rem' }}>Dashboard</Typography>
                        </Link>
                    </MenuItem>
                    
                    <MenuItem>
                        <Link href="/about" underline='none' color='white'>
                            <Button variant="primary" sx={{ textAlign: 'center', fontFamily: 'editundo', fontSize: '1.1rem' }} alignItems="center">About</Button>
                        </Link>
                    </MenuItem>
                    
                    <MenuItem>
                        <Link href='/contact' underline='none' color='white'>
                            <Button variant="primary" sx={{ textAlign: 'center', fontFamily: 'editundo', fontSize: '1.1rem' }} alignItems="center">Contact</Button>
                        </Link>
                    </MenuItem>

                    <MenuItem>
                        <Link href='/shop' underline='none' color='white'>
                            <Button variant='primary' sx={{textAlign: 'center', fontFamily: 'editundo', fontSize: '1.1rem'}} alignItems='center'>Shop</Button>
                        </Link>
                    </MenuItem>
                    
                    <Divider  sx={{ mt: 2, mx: 0, mb: 2, borderColor: 'whitesmoke', borderWidth: '0.03rem' }}/>
                    
                    <MenuItem key='Logout' onClick={logoutHandler}>
                        <Typography className='custom-typorgraphy' sx={{ textAlign: 'center', fontFamily: 'editundo', fontSize: '1.25rem'}}>Logout</Typography>
                    </MenuItem>
                
                </Menu>
            </Box>
    )
}

export default UserMenu;