import { Stack } from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../Media/Logo.png';
import Image from 'react-bootstrap/Image';
import MobileMenu from './MobileMenu.js';

export default function MobileBar() {
    
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); //Test for browser type being used by user-agent.

    const extendedTheme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 576,
            }
        }
    })
    
    const logoRef = useRef(null); // Ref for logo size.
    const [logoSize, setLogoSize] = useState(40); // State to store logo size.

    // Use useEffect to grab logo size after the component mounts.
    useEffect(() => {
        if (logoRef.current) {
            const { height } = logoRef.current.getBoundingClientRect();
            setLogoSize(height);
        }
    }, []); //Run only once on component mount.

    return(
        <ThemeProvider theme={extendedTheme}>
        <Stack direction='row' sx={{ 
            display: {xs: 'flex', sm: 'none'}, 
            py: '0.5em', 
            px: isSafari ? '0px' : undefined ,
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            <Image ref={logoRef} src={logo} width="25%" alt="logo"/>
            <div>
            <MobileMenu logoSize={logoSize}/>
            </div>
        </Stack>
        </ThemeProvider>
    )
}