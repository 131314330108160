import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/*import { grey } from '@mui/material/colors';*/

const theme = createTheme();

theme.typography.h1 = {
    fontFamily: 'editundo',
    fontSize: '1.8rem',
    '@media (min-width:600px)': {
      fontSize: '1.85rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
    },
    color: 'rgb(255, 255, 255)',
    padding: '3.5em',
    textShadow: '2px 2px 4px rgba(0,0,0, 0.5)',
    fontWeight: 'bolder'
  };

export default function LandingText() {
    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ [theme.breakpoints.down('sm')]: {
                    width: '100%', 
                    maxWidth: '500px', 
                    position: 'fixed', 
                    top: '33%', 
                    left: '-80px', 
                    transform: 'translateY(-50%)', 
                    textAlign: 'left', 
                    paddingLeft: '0rem'
                  },
                  [theme.breakpoints.up('sm')]: {
                    width: '100%', 
                    maxWidth: '1000px', 
                    position: 'relative', 
                    top: '0%', 
                    left: '0px', 
                    transform: 'translateY(-50%)', 
                    textAlign: 'left', 
                    paddingLeft: '0rem',
                    paddingTop: '30%'
                  },
                  //Adjust for landscape orientation on mobile.
                  '@media (orientation: landscape)': {
                    top: '20%',
                    left: '-100px',
                    maxWidth: '90%',
                    transform: 'translateY(-52%)'
                  }
              }}>
            <Typography variant='h1' gutterBottom>
                The Ground Truth. <br /><br />
                <Typography sx={{display: 'inline'}}>
                  <Typography component="span" style={{ 
                                                        color: 'rgb(29, 192, 254)', fontFamily: 'editundo', fontSize: '2.0rem'
                                                        
                                                        }}>Since </Typography>
                  <Typography component="span" style={{ color: 'rgb(247,0,147)', fontFamily: 'editundo', fontSize: '2.0rem'}}>2018.</Typography> 
                </Typography>
            </Typography>
        </Box>
        </ThemeProvider>
    )
}