import { firebaseAppAuth } from '../../Utils/Authentication/Authenticate';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const GoogleAuth = async (e) => {
    
    e.preventDefault();

    const provider = new GoogleAuthProvider();
    const auth = firebaseAppAuth;
    const user = await signInWithPopup(auth, provider);

}

export default GoogleAuth;