import { Box, Grid, Paper, Typography, Button } from '@mui/material';
import '../../Styles/shop.css'; // Centralized styles.
import AddToCartButton from "./AddToCart";

const ShopContent = ({addToCart}) => {
    const items = [
        {   
            id: 1,
            title: 'Zidualz No. 1',
            description: 'Exclusive items with in-depth insights and conversations about advancing technologies and trends.',
            price: '$19.99',
            thumbnail: require('../../Media/Thumbnail.png')
        },
        {   
            id: 2,
            title: 'Zidualz No. 2',
            description: 'Exclusive items with in-depth insights and conversations about advancing technologies and trends.',
            price: '$29.99',
            thumbnail: require('../../Media/Thumbnail.png')
        },
        {   
            id: 3,
            title: 'Zidualz No. 3',
            description: 'Exclusive items with in-depth insights and conversations about advancing technologies and trends.',
            price: '$19.99',
            thumbnail: require('../../Media/Thumbnail.png')
        },
        {   id: 4,
            title: 'Zidualz No. 4',
            description: 'Subscription for all-access features to entire Zidualz library.',
            price: '$9.99/month',
            thumbnail: require('../../Media/Thumbnail.png')
        },
        {   
            id: 5,
            title: 'Zidualz No. 5',
            description: 'Comprehensive transaction history data set.',
            price: '$49.99',
            thumbnail: require('../../Media/Thumbnail.png')
        }
    ]
    return(
        <Box sx={{
            width: '100%',
            paddingTop: 1, //Add space between ShopBanner and ShopContent
            paddingBottom: 4,
            bgcolor: 'linear-gradient(135deg, #1a1a40, #4a4a7d)', //Ensure a distinct background for content.
            }}>
                <Grid container spacing={3} 
                                sx={{
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                        {items.map(
                            (item, index) => (
                            <Grid
                            item
                            key={index}
                            xs={12}
                            sm={6}
                            md={4}
                            sx={{display: 'flex', justifyContent: 'center'}}>
                                
                            <Paper
                            elevation={16}
                            sx={{
                                width: 300,
                                height: 400,
                                bgcolor: 'linear-gradient(145deg, #222831, #393e46)', // Retro dark base color
                                borderRadius: 4,
                                border: '4px solid', //Bright arcade border
                                borderColor: '#ffcc00',
                                boxShadow: '0px 0px 15px rgba(251, 0, 255, 0.8)',
                                padding: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'center',
                                mb: 1,
                                transition: 'transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.0)',
                                    boxShadow: '0px 0px 25px rgb(255, 69, 0, 0.75)',
                                    borderColor: '#ff4500'
                                }
                                }}
                                >
                            
                            {/* Thumbnail */}
                            
                            <Box
                            component='img'
                            src={item.thumbnail}
                            alt={item.title}
                            sx={{
                                width: '100%',
                                height: '150px',
                                objectFit: 'cover',
                                borderRadius: 2,
                                marginBottom: 2,
                                boxShadow: '0px 4px 8px rgba(0,0,0, 0.8)'
                            }}
                            />
                        {/* Title */}
                        <Typography variant='h6' sx={{
                            fontFamily: '"Press Start 2P", sans-serif',
                            fontSize: 16,
                            color: '#ffcc00',
                            textShadow: '0px 0px 8px #ff6600',
                            marginBottom: 1
                        }}>
                            {item.title}
                        </Typography>
                            {/* Description */}
                        <Typography
                            variant='body2'
                            sx={{
                                color: 'white',
                                fontFamily: 'editundo, sans-serif',
                                marginBottom: 2
                            }}>
                                {item.description}
                            </Typography>
                            {/* Pricing */}
                            <Typography variant='h6'
                                        sx={{
                                            fontFamily: '"Press Start 2P", sans-serif',
                                            color: '#00ffcc',
                                            textShadow: '0px 0px 8px #00ffcc'
                                        }}
                            >
                                {item.price}
                            </Typography>
                            {/* Action Button */}
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2}}>
                            <Button variant='contained' sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                bgcolor: '#ff6600',
                                color: 'white',
                                fontFamily: 'ka1, sans-serif',
                                height: 35,
                                minWidth: 120,
                                "&:hover": {
                                    bgcolor: "#ff4500",
                                    boxShadow: "0px 0px 15px rgb(59, 0, 186)",
                                  }
                            }}>
                                Purchase
                            </Button>
                            <AddToCartButton item={item} addToCart={addToCart} />
                            </Box>
                        </Paper>
                    </Grid>
            )
            )
        }
        </Grid>
        </Box>
        )
    }

export default ShopContent;