import React, {useState} from 'react';
import ShopBanner from "./ShopBanner";
import ShopContent from "./ShopContent";
//import ShoppingCart from './ShoppingCart';
import ShoppingCartButton from './CartFab';
import {Box} from '@mui/material';

const Shop = () => {
    
    const [cart, setCart] = useState([]);

    const addToCart = (item) => {
        setCart((prevCart) => {
            const existingItem = prevCart.find((cartItem) => cartItem.id === item.id)
            if (existingItem) {
                return prevCart.map((cartItem) =>
                    cartItem.id === item.id
                    ? { ...cartItem, quantity: cartItem.quantity + 1 }
                    : cartItem
                )
            }
            return [...prevCart, { ...item, quantity: 1}]
        })
    }

    const removeFromCart = (id) => {
        setCart((prevCart) => prevCart.filter((cartItem) => cartItem.id !== id))
    }

    const updateCartItem = (id, quantity) => {
        setCart((prevCart) =>
            prevCart.map((cartItem) => 
                cartItem.id === id ? { ...cartItem, quantity: Math.max(1, quantity) } : cartItem
            )
        )
    }

    return(
        <Box sx={{ width: '100%', bgcolor: 'black'}}>
            <ShopBanner />
            <ShopContent addToCart={addToCart}/>
            <ShoppingCartButton cart={cart} removeFromCart={removeFromCart} updateCartItem={updateCartItem}/>
        </Box>
    )
}

export default Shop;