import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import OutlinedCard from './ProfileCard';
import TestProfile, { TestProTwo } from './TestProfile';

export default function Dashboard() {
  return (

      <Box sx={{ display: 'flex' }}>
        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            backgroundColor: 'rgb(255, 145, 0)'
          }}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
              backgroundColor: 'rgb(13, 0, 255)'
            }}>
            <OutlinedCard />
            {/*<TestProfile />*/}
            {/*<TestProTwo />*/}
            {/*<TestProThree />*/}
          </Stack>
        </Box>
      </Box>

  );
}