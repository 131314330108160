//import { useState } from 'react';
import * as firebase from 'firebase/app';
import { getAuth } from 'firebase/auth';
//import {firebaseConfigTwoPointO} from '../firebase.config';
import firebaseConfig from '../firebase.config';

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firebaseAppAuth = getAuth(firebaseApp);

export const currentUser = firebaseAppAuth.currentUser;

