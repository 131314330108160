//Redirect to login page on click.

import * as React from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../../Media/Logo.png';
import EmailPassAuth from '../Providers/EmailPass.js';

const SignUpPage = ({EmailPassAuth}) => {

    const theme = useTheme();
    
    const [showPassword, setShowPassword] = React.useState(false);
    
    const handleShowPwd = () => setShowPassword((show) => !show);
    
    const preventDefault = (e) => e.preventDefault();

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    }
    
    const handleMouseUpPassword = (e) => {
        e.preventDefault();
    }

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [mode, setMode] = React.useState("sign-in"); //Default mode is set to sign-in.

    const handleSubmit = (e) => {
        EmailPassAuth(e, email, password, mode); //Pass user input to the EmailPassAuth component.
    }

    return(
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Container component="main" maxWidth="xs">
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'background.paper', borderRadius: 1, p: 4, border: '1px solid', borderColor: alpha(theme.palette.grey[400], 0.4), boxShadow: theme.shadows[4]}}>
                        <img src={logo} alt="Zidualz Logo" style={{ height: 34}} title='Zidualz'></img>
                        <Typography variant="h5" color="textPrimary" sx={{ my: theme.spacing(1), textAlign: 'center', fontWeight: 600,}}>
                            Creat Account for Zidualz
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom textAlign="center">Welcome, please sign up to continue</Typography>
                    

                            <Divider sx={{ mt: 2, mx: 0, mb: 2 }} />
                                
                            <Box component="form" onSubmit={handleSubmit}>
                                <Stack direction="column" spacing={3} sx={{ mb: 2}}>
                                <TextField required fullWidth size='small' 
                                sx={{ pt: theme.spacing(0), pb: theme.spacing(1), fontSize: theme.typography.pxToRem(11), lineHeight: theme.typography.pxToRem(11), bgcolor: 'background.paper' }}
                                inputProps={{ style: { height: '24px', color: 'grey' } }}
                                label='Email' 
                                defaultValue='your@email.com' 
                                id='email-passkey' 
                                name='email'
                                value={email} //Bind value to the state.
                                onChange={(e) => setEmail(e.target.value)} //Update state on change.
                                />

                                <TextField required fullWidth size='small'
                                sx={{ pt: theme.spacing(0), pb: theme.spacing(1), fontSize: theme.typography.pxToRem(11), lineHeight: theme.typography.pxToRem(11) }}
                                InputProps={{
                                              endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label={ showPassword ? 'hide the password' : 'display the password' } 
                                                                                onClick={handleShowPwd}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                                onMouseUp={handleMouseUpPassword}
                                                                                >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ) 
                                            }}
                                inputProps={{ style: { height: '24px', color: 'grey' }}}
                                name="password" 
                                label="Password" 
                                id="password" 
                                placeholder="*****" 
                                autoComplete="current-password" 
                                defaultValue='*****'
                                type={showPassword ? "text" : "password"} //Toggle password visibility
                                value={password} //Bind the value to state.
                                onChange={(e) => setPassword(e.target.value)}
                                >
                                </TextField>

                                </Stack>
                                
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={12} sx={{ justifyContent: 'space-betewen' }}>
                                <FormControlLabel control= {
                                                            <Checkbox name="remember" value="true" color='primary' sx={{ padding: 0.5, '& .MuiSvgIcon-root': { fontSize: 11} }} />
                                                           }
                                                  label='Remember me'
                                                  slotProps={{
                                                    typography: {
                                                        color: 'textSecondary',
                                                        fontSize: theme.typography.pxToRem(14)
                                                    }
                                                  }}
                                                        
                                />
                        </Stack>
                        <Button 
                        type='submit' 
                        fullWidth size='large' 
                        variant='outlined' 
                        disableElevation 
                        color='inherit' 
                        sx={{ mt: 3,mb: 2, textTransform: 'capitalize' }}
                        onClick={() => setMode("sign-in")}
                        >Sign-up wih Email</Button>
                        <Divider sx={{ mt: 2, mx: 0, mb: 2 }} />
                </Box>
                    </Box>

                </Container>
            </Box>
        )
};

export default SignUpPage;